import React from "react";

// IMAGES
import logo from "assets/images/starter-screen/preloader-logo.svg";

const LogoScreen = () => (
  <div className="logo-screen">
    <img src={logo} alt="logo" />
  </div>
);

export default LogoScreen;
