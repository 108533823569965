import React from "react";

// IMAGES
import middleScreen from "assets/images/starter-screen/middle-screen.png";

const MiddleScreen = () => (
  <div className="middle-screen">
    <img src={middleScreen} alt="middle" />
  </div>
);

export default MiddleScreen;
