import React from "react";
import Image from "components/image/Image.component";
import bg from "assets/images/landing/like-block-img.png";
import Hashtags from "../hashtags/Hashtags.component";

const LikeBlock = () => {
  return (
    <div className="like-block">
      <Image src={bg} alt="" className="like-block__image" />
      <div className="like-block__content">
        <h1 className="like-block__title">Your like is worthy!</h1>
        <h1 className="like-block__subtitle">You can change the world!</h1>
        <Hashtags />
      </div>
    </div>
  );
};

export default LikeBlock;
