import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
// EFFECTS
import useDocumentTitle from "effects/useTitle.effect";
import useToolkit from "../../effects/useToolkit.effect";
// UTILITIES
import { setMetas } from "utilities/set-metas";
// IMAGES
// import logo from "assets/images/starter-screen/preloader-logo.svg";

const RouteWithMetas = ({
  isAuthenticated,
  path,
  children,
  title,
  description = "Faverity social app",
  // keywoards = "Social App, social, Faverity",
  ...restOfRoutes
}) => {
  const titlePrefix = "Faverity | ";

  const {
    reduxStore: { feeds: feedStore },
  } = useToolkit("authentication", "feeds", "common");

  const { pollsTagsList } = feedStore;

  useDocumentTitle(`${titlePrefix}${title}`);

  useEffect(() => {
    setMetas("og:title", `${titlePrefix}${title}`);
    setMetas("og:description", description);
    setMetas(
      "og:image",
      "http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg"
    );
    setMetas("og:keywoards", pollsTagsList && pollsTagsList.map((tag) => tag));
  });

  return (
    <>
      {/*<Helmet>*/}
      {/*  <meta charSet="utf-8" />*/}
      {/*  <meta property="og:title" content={`${titlePrefix}${title}`} />*/}
      {/*  <meta property="og:description" content={description} />*/}
      {/*  <meta*/}
      {/*    property="og:keywoards"*/}
      {/*    content={categories.length > 0 && categories.map((c) => c.name)}*/}
      {/*  />*/}
      {/*  <meta property="og:image" content={logo} />*/}
      {/*  <title>{`${titlePrefix}${title}`}</title>*/}
      {/*</Helmet>*/}
      <Route
        {...restOfRoutes}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
};

export default RouteWithMetas;
