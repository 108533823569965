import React from "react";

const MailIcon = ({ activeClases }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.113"
      height="24.302"
      className={activeClases}
      viewBox="0 0 33.113 24.302">
      <defs>
        <style dangerouslySetInnerHTML={{ __html: ".a{fill:#fff;}" }} />
      </defs>
      <path
        className="a"
        d="M28.554,68.125h-18.3a4.565,4.565,0,0,0-4.56,4.56v3.642H3.1a1.035,1.035,0,0,0,0,2.07H5.694v4.031H1.035a1.035,1.035,0,1,0,0,2.07H5.694v3.371a4.565,4.565,0,0,0,4.56,4.56h18.3a4.565,4.565,0,0,0,4.56-4.56V72.684A4.565,4.565,0,0,0,28.554,68.125Zm0,2.07a2.476,2.476,0,0,1,.923.178L19.4,78.919,9.33,70.373a2.476,2.476,0,0,1,.923-.178Zm2.49,17.673a2.493,2.493,0,0,1-2.49,2.49h-18.3a2.493,2.493,0,0,1-2.49-2.49V84.5H8.8a1.035,1.035,0,1,0,0-2.07H7.764V78.4H8.8a1.035,1.035,0,1,0,0-2.07H7.764V72.684a2.478,2.478,0,0,1,.137-.811l10.834,9.192a1.035,1.035,0,0,0,1.339,0l10.834-9.191a2.479,2.479,0,0,1,.136.811Z"
        transform="translate(0 -68.125)"
      />
    </svg>
  );
};

export default MailIcon;
