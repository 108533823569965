import React, { useState, useEffect } from "react";

// BASE COMPONENTS
import Notification from "components/notification/Notification.component";
// SLICES
import { setGlobalMessage } from "redux/common/common.slice";
// ACTIONS
import {
  getCountriesAsync,
  getCategoriesAsync,
  toggleAppFirstLauch,
} from "redux/common/common.actions";
// EFFECTS
import useToolkit from "effects/useToolkit.effect";
// ROUTES
import AppPages from "pages/AppPages.pages";
import StarterScreenPage from "../pages/starter-screen/StarterScreen.page";
// import AppLinks from "layouts/app-links/AppLinks.layout";
// APP STYLES
import "./App.scss";

function App() {
  const {
    dispatch,
    reduxStore: { common: commonStore },
  } = useToolkit("common");

  const [isLoading, setIsLoading] = useState(true);

  const { globalMessage, categories, isAppFirstLaunch } = commonStore;

  useEffect(() => {
    dispatch(getCountriesAsync());
    if (categories.length <= 0) {
      dispatch(getCategoriesAsync());
    }

    setTimeout(() => {
      setIsLoading(false);
      dispatch(toggleAppFirstLauch(false));
    }, 6000);
    // eslint-disable-next-line
  }, [])

  const handleNotificationClose = () => {
    dispatch(setGlobalMessage(null));
  };

  return (
    <main className="App">
      <Notification
        message={globalMessage?.text}
        isOpened={!!globalMessage}
        delay={7000}
        severity={globalMessage?.severity}
        handleNotificationClose={handleNotificationClose}
        position={{ vertical: "top", horizontal: "center" }}
      />
      {/* all app links to be crawled */}
      {/*<AppLinks />*/}
      {/* app routes */}
      {isLoading && isAppFirstLaunch ? <StarterScreenPage /> : <AppPages />}
    </main>
  );
}

export default App;
