import React from "react";
import { Route, Redirect } from "react-router-dom";

// EFFECTS
import useDocumentTitle from "effects/useTitle.effect";
// UTILITIES
// import { setMetas } from "utilities/set-metas";
// IMAGES
// import logo from "assets/images/starter-screen/preloader-logo.svg";

function PublicRoute({
  children,
  isAuthenticated,
  title,
  description = "Faverity social app",
  ...rest
}) {
  const titlePrefix = "Faverity | ";

  useDocumentTitle(`${titlePrefix}${title}`);

  // useEffect(() => {
  //   setMetas("og:title", `${titlePrefix}${title}`);
  //   setMetas("og:description", description);
  //   setMetas(
  //     "og:image",
  //     "http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg"
  //   );
  // });

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/feed",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PublicRoute;
