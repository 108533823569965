import React from "react";

import logo from "assets/images/landing/faverity.svg";
import search from "assets/images/landing/search.png";
import user from "assets/images/landing/user.png";
import Input from "components/input/Input.component";

const Header = () => {
  return (
    <header className="lading-header">
      <img src={logo} alt="logo" />
      <Input icon={search} placeholde="Search..." id="search" />
      <img src={user} alt="user" className="user" />
    </header>
  );
};

export default Header;
