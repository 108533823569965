const SettingsServices = {
  radioButtonsList: [
    {
      id: 1,
      value: "MALE",
      label: "Male",
    },
    {
      id: 2,
      value: "FEMALE",
      label: "Female",
    },
  ],
};

export default SettingsServices;
