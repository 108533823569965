import React from "react";

// BASE COMPONENTS
import GridContainer from "components/grid-container/GridContainer.component";
import GridItem from "components/grid-item/GridItem.component";
import Input from "components/input/Input.component";
import Form from "components/form/Form.component";
import Button from "components/button/Button.component";
// EFFECTS
import useInput from "effects/useInput.effect";
import useToolkit from "effects/useToolkit.effect";
// LAYOUTS
import { withToolbar } from "pages/feed/layouts/page-with-toolbar/PageWithToolbar.layout";
import BackArrowHeader from "pages/feed/layouts/back-arrow-header/BackArrowHeader.layout";

const PersonalInformation = () => {
  const {
    inputState,
    handleInput,
    handleInvalidMessage,
    invalidMessages,
  } = useInput();

  const {
    reduxStore: { authentication: authStore },
  } = useToolkit("authentication");

  let { userInfo } = authStore;

  console.log(userInfo);

  const handleInputChange = (event) => {
    handleInput(event);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <section className="personal-information-subpage">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <BackArrowHeader />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div className="personal-information-subpage__heading-text">
            Personal Information
          </div>
        </GridItem>
        <Form
          onSubmit={onSubmit}
          className="personal-information-subpage__form">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Input
              name="name"
              value={inputState.name}
              error={invalidMessages}
              onChange={handleInputChange}
              onInvalid={handleInvalidMessage}
              label="Name"
              type="text"
              placeholder="User name"
              required
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Input
              name="email"
              value={inputState.email}
              error={invalidMessages}
              onChange={handleInputChange}
              onInvalid={handleInvalidMessage}
              autoComplete="on"
              label="E-mail"
              type="text"
              placeholder="E-mail"
              required
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Input
              name="site"
              value={inputState.site}
              error={invalidMessages}
              onChange={handleInputChange}
              onInvalid={handleInvalidMessage}
              label="Web site"
              type="text"
              placeholder="Web site"
              required
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Input
              name="bio"
              value={inputState.bio}
              error={invalidMessages}
              onChange={handleInputChange}
              onInvalid={handleInvalidMessage}
              label="Bio"
              type="text"
              placeholder="Bio"
              required
            />
          </GridItem>
          <GridContainer className="fy-flex__center">
            <Button>Confirm</Button>
          </GridContainer>
        </Form>
      </GridContainer>
    </section>
  );
};

export default withToolbar(PersonalInformation);
