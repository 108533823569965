import React from "react";

// FEED ROUTE PAGES
import StarterScreenPage from "./starter-screen/StarterScreen.page";
import FeedPage from "./feed/feed/Feed.page";
import RegisterSuccess from "./auth/register/screens/register-success/RegisterSuccess.screen";
import ProfilePage from "./feed/profile/Profile.page";
import SearchPage from "./feed/search/Search.page";
import CreatePoll from "./feed/create-poll/CreatePoll.page";
import ChatsPage from "./feed/chat/Chat.page";
import CommentsPage from "./feed/comments/Comments.page";
import SubscribersPage from "./feed/subscribers/Subscribers.page";
// FEED ROUTES SUPBAGES
import PersonalInformationSubpage from "./feed/profile/subpages/personal-information/PersonalInformation.subpage";
import ChangePasswordSubpage from "./feed/profile/subpages/change-password/ChangePassword.subpage";
import SettingsSubpage from "./feed/profile/subpages/settings/Settings.subpage";
import SingleFeedSubpage from "./feed/feed/subpages/single-feed/SingleFeed.subpage";
import FeedOwnerProfileSubpage from "./feed/feed/subpages/poll-owner-profile/FeedOwnerProfile.subpage";
import PollPreviewSubpage from "./feed/create-poll/subpages/poll-preview/PollPreview.subpage";
import PersonalProfileSubpage from "./feed/profile/subpages/personal-profile/PersonalProfile.subpage";
import CommonPolls from "./feed/chat/subpages/common-polls/CommonPolls.subpage";
// AUTH ROUTES
import LoginPage from "./auth/login/Login.page";
import RegisterPage from "./auth/register/Register.page";
import PasswordResetPage from "./auth/password-reset/PasswordReset.page";
import FacebookRegisterPage from "./auth/register/screens/facebook-register/FacebookRegister.screen";
import EmailRegisterPage from "./auth/register/screens/email-register/EmailRegister.screen";
// LANDING PAGE /* should be removed after connecting to API */
import LandingPage from "./landing/Landing.page";
// UTILITIES
import { generateUID } from "utilities/helper-functions";

export const starterScreenRoutes = [
  {
    id: 4,
    path: "/starter-screen",
    title: "Starter Screen",
    child: <StarterScreenPage />,
    exact: false,
  },
];

export const authRoutes = [
  {
    id: generateUID(),
    path: "/login",
    title: "Login Page",
    child: <LoginPage />,
    exact: true,
  },
  {
    id: generateUID(),
    path: "/register",
    title: "Register Page",
    child: <RegisterPage />,
    exact: true,
  },
  {
    id: generateUID(),
    path: "/register/facebook",
    title: "Register Page | Facebook",
    child: <FacebookRegisterPage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/register/email",
    title: "Register Page | Email",
    child: <EmailRegisterPage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/reset-password",
    title: "Reset Password Page",
    child: <PasswordResetPage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/landing",
    title: "Landing Page",
    child: <LandingPage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/facebook-register-completed",
    title: "Facebook Register Successs",
    child: <RegisterSuccess />,
    exact: false,
  },
];

export const feedRoutes = [
  {
    id: generateUID(),
    path: "/feed",
    title: "Feed Page",
    child: <FeedPage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/search",
    title: "Search Page",
    child: <SearchPage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/create-poll",
    title: "Add Poll Page",
    child: <CreatePoll />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/register-success",
    title: "Register Success Page",
    child: <RegisterSuccess />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/personal-information",
    title: "Personal Information Page",
    child: <PersonalInformationSubpage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/change-profile",
    title: "Change Profile Page",
    child: <ProfilePage />,
    exact: true,
  },
  {
    id: generateUID(),
    path: "/change-profile/change-password",
    title: "Change Profile Page | Password",
    child: <ChangePasswordSubpage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/change-profile/settings",
    title: "Change Profile Page | Settings",
    child: <SettingsSubpage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/single-feed/:feedID",
    title: "Single Feed Page",
    child: <SingleFeedSubpage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/feed-owner-profile",
    title: "Feed Owner Profile Page",
    child: <FeedOwnerProfileSubpage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/poll-preview",
    title: "Poll Preview Page",
    child: <PollPreviewSubpage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/common-polls",
    title: "Common Polls Page",
    child: <CommonPolls />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/chat",
    title: "Chat Page",
    child: <ChatsPage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/comments",
    title: "Comments Page",
    child: <CommentsPage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/subscribers",
    title: "Subscribers Page",
    child: <SubscribersPage />,
    exact: false,
  },
  {
    id: generateUID(),
    path: "/personal-profile",
    title: "Personal Profile Page",
    child: <PersonalProfileSubpage />,
    exact: false,
  },
];

let availableAuthPaths = [];

authRoutes.forEach((route) => {
  availableAuthPaths.push(route.path);
  availableAuthPaths.push("/starter-screen");
});

const generateAvailableFeedPaths = (feedID) => {
  let availableFeedPaths = [];

  feedRoutes.forEach((route) => {
    if (route.path === "/single-feed/:feedID" && feedID !== undefined) {
      availableFeedPaths.push(`/single-feed/${feedID}`);
    }
    availableFeedPaths.push(route.path);
    availableFeedPaths.push("/starter-screen");
  });

  return availableFeedPaths;
};

export { availableAuthPaths, generateAvailableFeedPaths };
