import React from "react";

// BASE COMPONENTS
import Slider from "components/slider/Slider.component";
// SCREENS
import LogoScreen from "./screens/Logo.screen";
import MiddleScreen from "./screens/Middle.screen";
import LetsGoScreen from "./screens/Letsgo.screen";

const StarterScreenPage = () => {
  const screens = [
    { id: 1, item: <LogoScreen /> },
    { id: 2, item: <MiddleScreen /> },
    { id: 3, item: <LetsGoScreen /> },
  ];
  return (
    <section className="starter-screen-page">
      <Slider items={screens} autoplay={true} />
    </section>
  );
};

export default StarterScreenPage;
