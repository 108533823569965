import React from "react";

import girl from "assets/images/landing/best.png";

import Image from "components/image/Image.component";

const PhotoBlock = () => {
  return (
    <div className="photo-block">
      <Image src={girl} alt="" />
    </div>
  );
};

export default PhotoBlock;
