import React from "react";
import { useHistory } from "react-router-dom";

// BASE COMPONENTS
import Image from "components/image/Image.component";
import IsVisible from "components/is-visible/IsVisible.component";
// IMAGES
import backArrow from "assets/images/profile/back-arrow.svg";
import logo from "assets/images/starter-screen/preloader-logo.svg";

const BackArrowHeader = ({ isMainPage = false, hasLogo = true, text = "" }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="back-arrow-heading">
      <IsVisible isVisible={!isMainPage}>
        <Image
          src={backArrow}
          onClick={goBack}
          imgClassName="back-arrow"
          alt="back-arrow"
        />
      </IsVisible>
      <IsVisible isVisible={hasLogo}>
        <Image
          src={logo}
          hasLink={true}
          link="/feed"
          imgClassName="logo"
          alt="logo"
        />
      </IsVisible>
      <IsVisible isVisible={!hasLogo}>
        <h2>{text}</h2>
      </IsVisible>
    </div>
  );
};

export default BackArrowHeader;
