import React from "react";

const Hashtags = () => {
  return (
    <div className="hashtags">
      <span className="hashtags__btn">Moda</span>
    </div>
  );
};

export default Hashtags;
