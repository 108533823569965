import React from "react";
import { NavLink } from "react-router-dom";

// ICONS
import HomeIcon from "components/icons/HomeIcon";
import SearchIcon from "components/icons/SearchIcon";
import PlusIcon from "components/icons/PlusIcon";
import MailIcon from "components/icons/MailIcon";
import ProfileIcon from "components/icons/ProfileIcon";

const NavToolbar = () => {
  const navbarItems = [
    { id: 1, path: "/feed", icon: <HomeIcon />, activeClassName: "svg-active" },
    {
      id: 2,
      path: "/search",
      icon: <SearchIcon />,
      activeClassName: "svg-active",
    },
    {
      id: 3,
      path: "/create-poll",
      icon: <PlusIcon />,
      activeClassName: "svg-active",
    },
    { id: 4, path: "/chat", icon: <MailIcon />, activeClassName: "svg-active" },
    {
      id: 5,
      path: "/personal-profile",
      icon: <ProfileIcon />,
      activeClassName: "svg-active",
    },
  ];
  return (
    <footer className="toolbar-footer">
      <nav className="toolbar-footer__nav-toolbar">
        {navbarItems.map((item, idx) => (
          <NavLink
            exact
            to={item?.path}
            activeClassName={item?.activeClassName}
            key={item?.id || idx}>
            {item?.icon}
          </NavLink>
        ))}
      </nav>
    </footer>
  );
};

export default NavToolbar;
