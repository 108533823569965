import React, { useEffect } from "react";
import { Switch, useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";

// LAYOUTS
import RouteWithMetas from "layouts/metas-wrapper/MetasWrapper.layout.jsx";
// PUBLIC ROUTE
import PublicRoute from "layouts/public-route/PublicRoute.component";
// EFFECTS
import useToolkit from "effects/useToolkit.effect";
// ROUTES
import {
  authRoutes,
  feedRoutes,
  availableAuthPaths,
  generateAvailableFeedPaths,
  starterScreenRoutes,
} from "./routes";

const AppPages = () => {
  const {
    reduxStore: { authentication: authStore, feeds: feedsStore },
  } = useToolkit("authentication", "feeds");

  const { userInfo } = authStore;
  const { singleFeedData } = feedsStore;

  const token = userInfo?.accessToken;
  const location = useLocation();
  const history = useHistory();

  const availableFeedPaths = generateAvailableFeedPaths(singleFeedData?.id);

  useEffect(() => {
    // redirect to feed's page when feed route is not found
    if (!!token && !availableFeedPaths.includes(location.pathname)) {
      history.push("/feed");
    }
    // redirect to login page when auth route is not found
    if (!token && !availableAuthPaths.includes(location.pathname)) {
      history.push("/login");
    }
    // eslint-disable-next-line
  });

  const authPagesClasses = classNames({
    "auth-pages": true,
    "display-none": !!token === true,
  });

  const feedPagesClasses = classNames({
    "feed-pages": true,
    "display-none": !!token === false,
  });

  return (
    <>
      <div>
        <Switch>
          {starterScreenRoutes.map((route) => (
            <PublicRoute
              isAuthenticated={!!token}
              title={route.title}
              path={route.path}
              exact={route.exact}
              key={route.path}>
              {route.child}
            </PublicRoute>
          ))}
        </Switch>
      </div>
      <div className={authPagesClasses}>
        <Switch>
          {authRoutes.map((route) => (
            <PublicRoute
              isAuthenticated={!!token}
              title={route.title}
              path={route.path}
              exact={route.exact}
              key={route.path}>
              {route.child}
            </PublicRoute>
          ))}
        </Switch>
      </div>
      <div className={feedPagesClasses}>
        <Switch>
          {feedRoutes.map((route) => (
            <RouteWithMetas
              path={route.path}
              isAuthenticated={!!token}
              title={route.title}
              exact={route.exact}
              key={route.path}>
              {route.child}
            </RouteWithMetas>
          ))}
        </Switch>
      </div>
    </>
  );
};

export default AppPages;
