import React from "react";

// COMPONENTS
import LikeBlock from "./components/like-block/LikeBlock.component";
// import Hashtags from "./components/hashtags/Hashtags.component";
import PhotoBlock from "./components/photo-block/PhotoBlock.component";
import FeedsSliders from "./components/feed-sliders/FeedsSliders.component";
import Newsletters from "./components/newsletter/Newsletter.component";
import Subscribe from "./components/subsrcibe/Subscribe.component";

const Content = () => {
  return (
    <section className="landing-content">
      <LikeBlock />
      <PhotoBlock />
      <FeedsSliders />
      <Newsletters />
      <Subscribe />
    </section>
  );
};

export default Content;
