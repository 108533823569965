import React from "react";

// BASE COMPONENTS
import Button from "components/button/Button.component";
// IMAGES
import thirdScreen from "assets/images/starter-screen/third-screen.png";

const LetsGoScreen = () => (
  <div className="letsgo-screen">
    <img src={thirdScreen} alt="third" />
    <Button className="letsgo-screen__button">Let&apos;s Go</Button>
  </div>
);

export default LetsGoScreen;
