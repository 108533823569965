import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistStore } from "redux-persist";

// ROOT REDUCER
import rootReducer from "./root-reducer";

// CONFIGURE STORE
const store = configureStore({
  reducer: rootReducer,
  // add middlewares (both defaults and custom)
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false,
    });
    // add logger for only development
    if (process.env.NODE_ENV === "development") {
      return middlewares.concat(logger);
    }
    return middlewares;
  },
  // preloadedState: window.__PRELOADED_STATE__,
});

// Tell react-snap how to save Redux state
// window.snapSaveState = () => {
//   console.log("SAVE SNAP STATE");
//   return {
//     __PRELOADED_STATE__: store.getState(),
//   };
// };

export const persistor = persistStore(store);

export default store;
